<template>
  <div class="cert-details">
    <el-row :gutter="20" style="margin-bottom: 15px;">
      <el-col :span="12">
        <el-card class="box-card">
          <template #header>
            <div class="card-header">
              <span>基本信息</span>
            </div>
          </template>
          <div>
            <el-form ref="form" :model="form">
              <el-form-item>
                <el-input v-model="form.name" placeholder="ECert Title"></el-input>
              </el-form-item>
              <el-form-item>
                <el-select v-model="form.region" placeholder="Group">
                  <el-option label="Test Group1" value="Test Group1"></el-option>
                  <el-option label="Test Group2" value="Test Group2"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-date-picker type="date" placeholder="ECert Issue Date(ie. the date print on the Cert)" v-model="form.date" style="width: 100%;"></el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-input v-model="form.number" placeholder="ECert number"></el-input>
              </el-form-item>
            </el-form>

          </div>
        </el-card>
      </el-col>
      <el-col :span="12">
        Pre-approve Member 
        
      </el-col>
    </el-row>


    <el-row :gutter="20">

      <el-col :span="12">
        <el-card class="box-card">
          
          <template #header>
            <div class="card-header">
              <span>Manage</span>
            </div>
          </template>

          <div class="mb-20">
            <h4 class="mb-20">Certificate Content</h4>
            <image-upload :btnText="'Upload Background'" @imageFile="imageFile"></image-upload>
            <image-upload :btnText="'Upload Left Logo'" @imageFile="imageFile"></image-upload>
            <image-upload :btnText="'Upload Middle Logo'" @imageFile="imageFile"></image-upload>
            <bg-default @imageDefault="imageDefault"></bg-default>
          </div>

          <el-form ref="certContent" :model="certContent">
            <el-form-item>
              <el-input v-model="certContent.certificateName" placeholder="Certificate Name"></el-input>
            </el-form-item>
            <el-form-item>
              <el-input v-model="certContent.certificateSubTitle" placeholder="Certificate Sub-Title"></el-input>
            </el-form-item>
            <el-form-item label="Show Printed Name">
              <el-switch v-model="certContent.isPrinted"></el-switch>
            </el-form-item>
            <el-form-item label="Show Date of birth">
              <el-switch v-model="certContent.isDate"></el-switch>
            </el-form-item>
            <el-form-item label="Show Nationality">
              <el-switch v-model="certContent.isNationality"></el-switch>
            </el-form-item>
            <el-form-item>
              <el-input type="textarea" v-model="certContent.description1" placeholder="Certificate Description 1"></el-input>
            </el-form-item>
            <el-form-item>
              <el-input type="textarea" v-model="certContent.description2" placeholder="Certificate Description 2"></el-input>
            </el-form-item>
            <el-form-item>
              <el-input type="textarea" v-model="certContent.description3" placeholder="Certificate Description 3"></el-input>
            </el-form-item>

            <image-upload :btnText="'Upload Signature 1'" @imageFile="imageFile"></image-upload>
            <el-form-item>
              <el-input v-model="certContent.personTitle1" placeholder="Authorized  1"></el-input>
            </el-form-item>

            <image-upload :btnText="'Upload Signature 2'" @imageFile="imageFile"></image-upload>
            <el-form-item>
              <el-input v-model="certContent.personTitle2" placeholder="Authorized  2"></el-input>
            </el-form-item>

          </el-form>

          <button v-print="'#certView'">打印</button>

        </el-card>
      </el-col>

      <el-col :span="12">
        <div id="certView">

          <div v-for="(item, index) in 1" :key="index" class="cert-view" :style="{backgroundImage: 'url(' + background.url + ')'}" style="page-break-after:always">

            <div class="cert-wrap">

              <el-row :gutter="20">
                <el-col :span="8">
                  <div class="cert-header-logo">
                    <img :src="leftLogo.url">
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="cert-header-logo">
                    <img :src="middleLogo.url">
                  </div> 
                </el-col>
                <el-col :span="8">
                  <div class="cert-header-logo">
                    <!-- <img :src="middleLogo.url"> -->
                  </div>
                </el-col>
              </el-row>

              <div class="cert-content">
                <h3 class="cert-name">{{ certContent.certificateName }}{{index}}</h3>
                <p class="cert-sub-title">{{ certContent.certificateSubTitle }}</p>
                <p class="cert-printed-name"><span v-if="certContent.isPrinted">Printed Name</span></p>
                <p class="cert-date"><span v-if="certContent.isDate">Date of birth</span></p>
                <p class="cert-nationality"><span v-if="certContent.isNationality">Nationality</span></p>
                <p class="cert-text cert-text-desc" v-html="certContent.description1"></p>
                <p class="cert-date">Issue date</p>
                <p class="cert-text">{{ certContent.description2 }}</p>
                <p class="cert-date">Valid date</p>
                <p class="cert-text">{{ certContent.description3 }}</p>
              </div>

              <el-row :gutter="20" style="margin-right:10px;margin-left: 10px;">
                <el-col :span="12">
                  <div class="cert-footer-logo">
                    <img :src="person1.url" alt="Authorized person Title">
                    <p>{{ certContent.personTitle1 }}</p>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="cert-footer-logo">
                    <img :src="person2.url" alt="Authorized person Title">
                    <p>{{ certContent.personTitle2 }}</p>
                  </div>
                </el-col>
              </el-row>

            </div>


          </div>



        </div>


      </el-col>

    </el-row>

  </div>
</template>

<script>
import ImageUpload from '../../components/imageUpload/ImageUpload'
import BgDefault from './component/BgDefault.vue'
import {ref} from 'vue'
export default {
  name: 'certDetails',
  data(){
    return{
      form: {
        name: '',
        region: '',
        date: '',
        number: ''
      },
      certContent: {
        certificateName: 'Certificate Name',
        certificateSubTitle: 'This certificate is proudly presented to',
        isPrinted: true,
        isDate: true,
        isNationality: false,
        description1: 'This is a sample text. Insert yout desired text here.This is a sample text. Insert yout desired text here.',
        description2: 'This is a sample text. Insert yout desired text here.',
        description3: 'This is a sample text. Insert yout desired text here.This is a sample text. Insert yout desired text here.',
        personTitle1: 'Authorized person Title1',
        personTitle2: 'Authorized person Title2'
      },
      background: '',
      leftLogo: '',
      middleLogo: '',
      person1: '',
      person2: ''
    }
  },
  setup(){
  },

  components: {
    ImageUpload,
    BgDefault
  },
  computed: {
  },
  methods:{
    imageFile(imageFile){
      if(imageFile.text === 'Upload Background'){
        this.background = imageFile;
        return;
      } else if(imageFile.text === 'Upload Left Logo'){
        this.leftLogo = imageFile;
        return;
      } else if(imageFile.text === 'Upload Middle Logo'){
        this.middleLogo = imageFile;
        return;
      } else if(imageFile.text === 'Upload Signature 1'){
        this.person1 = imageFile;
        return;
      } else if(imageFile.text === 'Upload Signature 2'){
        this.person2 = imageFile;
        return;
      }
    },
    imageDefault(src){
      console.log(src)
      this.background = {
        url: src
      };
      return;
    }
    
  }
}
</script>

<style>
@page {  
  size: auto A4 landscape;
  margin-top: 0mm;
  margin-bottom: 0mm;
  margin-left: 0mm; 
  margin-right: 0mm
}

@media print {
  .cert-view{
    -webkit-print-color-adjust:exact;
    -moz-print-color-adjust:exact;
    -ms-print-color-adjust:exact;
    page-break-before:always;
  } 
  html, body {
        height: inherit;
    }
  #certView{
    height: inherit !important;
    overflow: inherit;
  }
}

</style>

<style scoped>
.el-select{
  width: 100%;
}

.mb-20{
  margin-bottom: 20px;
}

.cert-view{
  background-color: azure;
  background-repeat:no-repeat;
  background-position: center center;
  background-size: cover;
  padding: 50px;
  width: 700px;
  height: 1021px;
}
.cert-wrap{
  padding: 15px;
  background-color: antiquewhite;
}
.cert-header-logo{
  position: relative;
  height: 180px;
}
.cert-header-logo img{
  max-width: 100%;
  max-height: 180px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.cert-content{
  text-align: center;
}
.cert-name{
  font-size: 30px;
  color: #182389;
  height: 40px;
  line-height: 40px;
}
.cert-sub-title{
  font-size: 20px;
  color: #182389;
  margin-bottom: 30px;
  height: 26px;
  line-height: 26px;
}
.cert-printed-name{
  font-size: 25px;
  color: #454a46;
  height: 34px;
  line-height: 34px;
}
.cert-date{
  color: #454a46;
  font-size: 18px;
  height: 24px;
  line-height: 24px;
}
.cert-nationality{
  font-size: 18px;
  color: #454a46;
  margin-bottom: 30px;
  height: 24px;
  line-height: 24px;
}

.cert-text{
  height: 72px;
  color: #182389;
  font-size: 18px;
  margin-bottom: 29px;
  white-space: pre;
  white-space: pre-line;
  word-wrap: break-word;
}
.cert-text-desc{
  display: -webkit-box; 
  -webkit-box-orient: vertical; 
  -webkit-line-clamp: 3; 
  overflow: hidden;
}
.cert-footer-logo{
  text-align: center;
}
.cert-footer-logo p{
  color: #182389;
  font-size: 16px;
  margin-bottom: 30px;
}
.cert-footer-logo img{
  width: 200px;
  height: 200px;
  object-fit: contain;
  display: block;
  margin: 0 auto;
}
</style>